import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import AnalyticsRightRoundOne from "../images/AnalyticsRightRoundOne.png"
import Shapefour from "../images/bannerElement/white-design-element-analytics.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import ActionableAnalytics from "../images/icons/MarketingAnalytics/ActionableAnalytics.svg"
import AutomatedInsights from "../images/icons/MarketingAnalytics/AutomatedInsights.svg"
import FullPicture from "../images/icons/MarketingAnalytics/FullPicture.svg"
import PredictiveIntelligence from "../images/icons/MarketingAnalytics/PredictiveIntelligence.svg"
import thaleslogo from "../images/thales-logo.svg"
import NiceSatmetrixNew from "../images/NiceSatmetrixNew.svg"
import "../styles/404.css"

const CreativeAssets = ({ siteTitle }) => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Marketing Analytics | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="marketing-analytics digital-consulting">
					<section id="Banner">
						<div class="container">
							<div class="banner-holder">
								<div class="images">
									<div className="img-one image-shape">
										<img src={Shapeone} alt="Image" />
									</div>
									<div className="img-two image-shape">
										<img src={Shapetwo} alt="Image" />
									</div>
									<div className="img-three image-shape">
										<img src={Shapethree} alt="Image" />
									</div>
									<div className="img-four image-shape">
										<img src={Shapefour} alt="Image" />
									</div>
								</div>
								<div class="title">
									<div class="banner-title">
										<h1>Stop guessing; start knowing your data</h1>
										<p>Gain actionable insights to improve performance across every touchpoint.</p>
										<a className="button" id="Get-in-touch" href="/contact-us/">Talk to an expert</a>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="PageIntro">
						<div class="container">
							<h2>Accelerate decision-making and drive growth</h2>
							<p>Empower your decisions with precise, timely data insights tailored for stakeholders. Our comprehensive suite of audit, reporting, analytics, and attribution services provides a 360-degree view of your digital ecosystem, illuminating the path to success. With our expertise, you'll gain a deep understanding of your audience, uncover hidden opportunities, and make informed decisions that propel your business forward.</p>
						</div>
					</section>
					<section id="WeDeliver">
						<div class="container">
							<h2>Get a complete view of your digital ecosystem</h2>
							<p>Unlock the full potential of your data and transform insights into actionable strategies that drive tangible results. Easily optimize marketing campaigns, track mission-critical KPIs, or forecast future trends with our advanced analytics solutions that deliver the clarity you need.</p>
							<div class="horizontalImageIconSec">
								<div class="moduleWrap">
									<div class="eachModule">
										<div class="icons">
											<img width="100" height="100" src={ActionableAnalytics} />
										</div>
										<div class="contents">
											<h3>Actionable Analytics</h3>
											<p>Optimize marketing and track KPIs with clear, comprehensive reporting.</p>
										</div>
									</div>
									<div class="eachModule">
										<div class="icons">
											<img width="100" height="100" src={FullPicture} />
										</div>
										<div class="contents">
											<h3>Full Picture</h3>
											<p>Centralized cross-channel analytics gives a holistic perspective of marketing efforts.</p>
										</div>
									</div>
									<div class="eachModule">
										<div class="icons">
											<img width="100" height="100" src={PredictiveIntelligence} />
										</div>
										<div class="contents">
											<h3>Predictive Intelligence</h3>
											<p>Unveil future business landscapes with our AI-driven forecasts.</p>
										</div>
									</div>
									<div class="eachModule">
										<div class="icons">
											<img width="100" height="100" src={AutomatedInsights} />
										</div>
										<div class="contents">
											<h3>Automated Insights</h3>
											<p>Get instant insights delivered directly to your stakeholders.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="Parallax" className="Parallaxsection1" style={{backgroundImage: "url(" + AnalyticsRightRoundOne + ")",}}>
						<div class="holder">
							<div class="container">
								<div class="content-wrapper">
									<div class="left-content">
										<div class="animation-text">
											<h2>Accelerate your growth through data</h2>
											<p>Our analytics team helps create a competitive advantage by identifying your:
											<ul class="bullet-point">
												<li>Key audience segments for maximum value</li>
												<li>Untapped opportunities for substantial growth</li>
												<li>Innovation areas for market leadership</li>
											</ul>
											<strong>Our data to insights to action methodology helps fine-tune strategies, forecast trends, and grow revenue, all backed by real-time analytics.</strong>
											</p>
										</div>
									</div>
									<div class="mobile-img">
										<img src={AnalyticsRightRoundOne} alt="" />
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="Parallax" style={{backgroundImage: "url(" + NiceSatmetrixNew + ")",}} >
						<div class="holder">
							<div class="container">
								<div class="content-wrapper">
									<div class="left-content">
										<div class="animation-text">
											<span class="quote"></span>
											<p>We consider Position<sup>2</sup> as part of our larger analytics and web teams. They support us on designing and building analytic dashboards in both Salesforce and DOMO, they provide updates and weekly insights, as well as using analytics to drive improved SEO, Conversion and understanding of our broad Marketing initiatives. </p>
											<div class="emp-info">
												<p>
													<span>Jack Speyer</span>Director, Marketing Operations and Analytics <br />Thales Cloud Protection and Licensing
												</p>
											</div>
										</div>
									</div>
									<div class="mobile-img">
										<img src={thaleslogo} alt="" />
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="contactus-section">
						<div class="container">
							<h2>Transform insights into actionable strategies</h2>
							<a className="button" id="Get-in-touch" href="/contact-us/">Talk to an expert</a>
						</div>
					</section>
				</div>
      </Layout>
    </>
  )
}

export default CreativeAssets
